<template>
  <div
    class="accordion"
    role="tablist"
  >
    <div
      class="accordion-item accordion-item_master"
    >
      <div
        class="accordion-label"
        :class="visible ? 'not-collapsed' : 'collapsed'"
        @click="visible = !visible; fetchItems()"
      >
        <div class="accordion-label__plus">
          <img
            v-if="visible"
            src="../../assets/images/icons/minus.svg"
            alt=""
          >
          <img
            v-if="!visible"
            src="../../assets/images/icons/plus.svg"
            alt=""
          >
        </div>
        <div class="accordion-label__content">
          {{ master.first_name }} {{ master.last_name }}
        </div>
        <div class="accordion-label__stats">
          <div class="accordion-label__stats-item">
            {{ master.client_link_sent_count }}
          </div>
          <div class="accordion-label__stats-item">
            {{ master.client_link_followed_count }}
            <div class="accordion-label__stats-bar">
              <div
                :style="{width: `${(master.client_link_followed_count/master.client_link_sent_count) * 100}%`}"
                class="accordion-label__stats-progress"
              />
            </div>
          </div>
          <div class="accordion-label__stats-item">
            {{ master.client_accepted_count }}
            <div class="accordion-label__stats-bar">
              <div
                :style="{width: `${(master.client_accepted_count/master.client_link_followed_count) * 100}%`}"
                class="accordion-label__stats-progress"
              />
            </div>
          </div>
        </div>
      </div>
      <b-collapse
        :id="`accordion-master-${locationId}-${master.id}`"
        v-model="visible"
        role="tabpanel"
        class="accordion__collapse"
      >
        <MasterItem
          v-for="(item, indexClient) in clients"
          :key="indexClient"
          :client="item"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BCollapse,
} from 'bootstrap-vue'
import MasterItem from './MasterItem.vue'

export default {
  components: {
    BCollapse,
    MasterItem,
  },
  props: {
    master: {
      type: Object,
      default() {
        return {}
      },
    },
    locationId: {
      type: Number,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      visible: false,
      clients: [],
    }
  },
  methods: {
    ...mapActions({
      fetchClients: 'masters/fetchClients',
    }),
    async fetchItems() {
      this.clients = await this.fetchClients({ masterId: this.master.id, siteContactId: this.locationId })
    },
  },
}
</script>
