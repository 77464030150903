<template>
  <div class="numbers">
    <div class="numbers-item">
      <div class="numbers-header">
        <span class="numbers-number">{{ stats.client_link_sent_count }}</span>
      </div>
      <hr>
      <span class="numbers-text">Отправлено ссылок</span>
    </div>
    <div class="numbers-item">
      <div class="numbers-header">
        <span class="numbers-number">{{ stats.client_link_followed_count }}</span>
        <ul
          v-for="(item, indexFollowed) in stats.client_link_followed_count_grouped_by_type"
          :key="indexFollowed"
          class="numbers-list"
        >
          <li class="numbers-list-item">
            <span class="numbers-list-number">{{ item.count }}</span>
            <div class="numbers-list-icon">
              <img
                :src="require(`../../assets/images/icons/${item.type}.svg`)"
                :alt="item.type"
              >
            </div>
          </li>
        </ul>
      </div>
      <hr>
      <span class="numbers-text">Прошли по ссылке</span>
    </div>
    <div class="numbers-item">
      <div class="numbers-header">
        <span class="numbers-number">{{ stats.client_accepted_count }}</span>
        <ul
          v-for="(item, indexAccepted) in stats.client_accepted_count_grouped_by_type"
          :key="indexAccepted"
          class="numbers-list"
        >
          <li class="numbers-list-item">
            <span class="numbers-list-number">{{ item.count }}</span>
            <div class="numbers-list-icon">
              <img
                :src="require(`../../assets/images/icons/${item.type}.svg`)"
                :alt="item.type"
              >
            </div>
          </li>
        </ul>
      </div>
      <hr>
      <span class="numbers-text">Проверено отзывов</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      stats: 'masters/getStats',
    }),
  },
}
</script>
