<template>
  <div>
    <FeedbackFilter />
    <Numbers />
    <LocationsStatsList />
  </div>
</template>

<script>
import FeedbackFilter from '@/components/filter/Filter.vue'
import Numbers from '@/components/numbers/Numbers.vue'
import LocationsStatsList from '@/components/stats/LocationsStatsList.vue'

export default {
  components: {
    FeedbackFilter,
    Numbers,
    LocationsStatsList,
  },
}
</script>
