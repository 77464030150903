<template>
  <div
    class="accordion-item accordion-item_client"
  >
    <div
      class="accordion-label"
      :class="visible ? 'not-collapsed' : 'collapsed'"
      @click="visible = !visible;"
    >
      <div class="accordion-label__plus">
        <img
          v-if="visible"
          src="../../assets/images/icons/minus.svg"
          alt=""
        >
        <img
          v-if="!visible"
          src="../../assets/images/icons/plus.svg"
          alt=""
        >
      </div>
      <div class="accordion-label__content">
        {{ client.first_name }} {{ client.last_name }}
      </div>
      <div class="accordion-label__stats">
        <div class="accordion-label__stats-item">
          <span v-if="client.sms_sent_at !== null">{{ new Date(client.sms_sent_at).toLocaleDateString('ru-RU') }}</span>
        </div>
        <div class="accordion-label__stats-item">
          <span v-if="client.followed_at !== null">{{ new Date(client.followed_at).toLocaleDateString('ru-RU') }}</span>
        </div>
        <div class="accordion-label__stats-item" />
      </div>
    </div>
    <b-collapse
      :id="`accordion-client-${client.id}`"
      v-model="visible"
      role="tabpanel"
      class="accordion__collapse"
    >
      <div class="sources">
        <div
          v-for="(conversion, indexConversion) in client.conversions"
          :key="indexConversion"
          class="source-item"
        >
          <span class="source-name">{{ conversion.name }}</span>
          <div class="accordion-label__stats">
            <div class="accordion-label__stats-item">
              <span v-if="client.sms_sent_at !== null">{{ new Date(client.sms_sent_at).toLocaleDateString('ru-RU') }}</span>
            </div>
            <div class="accordion-label__stats-item">
              <span v-if="conversion.followed_at !== null">{{ new Date(conversion.followed_at).toLocaleDateString('ru-RU') }}</span>
            </div>
            <div class="accordion-label__stats-item">
              <b-form-checkbox
                :id="`is-accepted-${conversion.id}`"
                v-model="conversion.accepted"
                :name="`is-accepted-${conversion.id}`"
                @input="changeAccepted(conversion.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCollapse, BFormCheckbox,
} from 'bootstrap-vue'
import Master from '@/api/http/models/master/Master'

export default {
  components: {
    BCollapse,
    BFormCheckbox,
  },
  props: {
    client: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      visible: false,
      clients: [],
    }
  },
  methods: {
    async changeAccepted(id) {
      await Master.confirmReview(id)
    },
  },
}
</script>
