<template>
  <div
    class="accordion-item accordion-item_location"
  >
    <div
      class="accordion-label"
      :class="visible ? 'not-collapsed' : 'collapsed'"
      @click="visible = !visible; fetchItems()"
    >
      <div class="accordion-label__plus">
        <img
          v-if="visible"
          src="../../assets/images/icons/minus.svg"
          alt=""
        >
        <img
          v-if="!visible"
          src="../../assets/images/icons/plus.svg"
          alt=""
        >
      </div>
      <div class="accordion-label__content">
        {{ location.name }} - {{ location.address }}
      </div>
      <div class="accordion-label__stats">
        <div class="accordion-label__stats-item">
          {{ location.client_link_sent_count }}
        </div>
        <div class="accordion-label__stats-item">
          {{ location.client_link_followed_count }}
          <div class="accordion-label__stats-bar">
            <div
              :style="{width: `${(location.client_link_followed_count/location.client_link_sent_count) * 100}%`}"
              class="accordion-label__stats-progress"
            />
          </div>
        </div>
        <div class="accordion-label__stats-item">
          {{ location.client_accepted_count }}
          <div class="accordion-label__stats-bar">
            <div
              :style="{width: `${(location.client_accepted_count/location.client_link_followed_count) * 100}%`}"
              class="accordion-label__stats-progress"
            />
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      :id="`accordion-location-${location.id}`"
      v-model="visible"
      role="tabpanel"
      class="accordion__collapse"
    >
      <MasterList
        v-for="(master, indexMaster) in masters"
        :key="indexMaster"
        :master="master"
        :location-id="location.id"
      />
    </b-collapse>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BCollapse,
} from 'bootstrap-vue'
import MasterList from './MasterList.vue'

export default {
  components: {
    MasterList,
    BCollapse,
  },
  props: {
    location: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      visible: false,
      masters: [],
    }
  },
  methods: {
    ...mapActions({
      fetchLocationMasters: 'masters/fetchLocationMasters',
    }),
    async fetchItems() {
      this.masters = await this.fetchLocationMasters(this.location.id)
    },
  },
}
</script>
