<template>
  <div
    class="accordion accordion_clients"
    role="tablist"
  >
    <div class="accordion__header">
      <span class="accordion__header-item">Мастер-консультанты</span>
      <div class="accordion__header-list">
        <span class="accordion__header-item">Отправлено</span>
        <span class="accordion__header-item">Перешли</span>
        <span class="accordion__header-item">Подтверждено</span>
      </div>
    </div>
    <LocationsStatsItem
      v-for="(location, indexMaster) in locations"
      :key="indexMaster"
      :location="location"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocationsStatsItem from './LocationsStatsItem.vue'

export default {
  components: {
    LocationsStatsItem,
  },
  computed: {
    ...mapGetters({
      locations: 'masters/getLocations',
      date: 'masters/getDate',
      tab: 'masters/getTab',
    }),
  },
}
</script>
