<template>
  <div class="filter">
    <Tabs
      v-model="tab"
      :options="options"
      @input="changeDateTabs"
    />
    <date-picker
      v-model="date"
      range
      range-separator=" - "
      :formatter="format"
      @input="changeDatePicker"
    >
      <template slot="icon-calendar">
        <img
          src="../../assets/images/icons/calendar.svg"
          alt=""
        >
      </template>
    </date-picker>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import 'vue2-datepicker/locale/ru'
import Tabs from '@/components/tabs/Tabs.vue'
import { mapGetters, mapActions } from 'vuex'

/* eslint-disable */
Date.prototype.addDays = function(days) {
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date;
}

Date.prototype.removeDays = function(days) {
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() - days)
  return date;
}

Date.prototype.removeMonths = function(months) {
  let date = new Date(this.valueOf())
  date.setMonth(date.getMonth() - months)
  return date;
}
/* eslint-enable */

export default {
  components: {
    Tabs,
    DatePicker,
  },
  data() {
    return {
      clients: {},
      stats: {},
      format: {
        /* eslint-disable */
        stringify: date => date ? moment(date).format('D MMM YYYY') : '',
        parse: value => value ? moment(value, 'D MMM YYYY').toDate() : null,
        /* eslint-enable */
      },
    }
  },
  computed: {
    tab: {
      get() {
        return this.$store.state.masters.tab
      },
      set(value) {
        this.$store.commit('masters/SET_TAB', value)
      },
    },
    date: {
      get() {
        return this.$store.state.masters.date
      },
      set(value) {
        this.$store.commit('masters/SET_DATE', value)
      },
    },
    ...mapGetters({
      options: 'masters/getOptions',
    }),
  },
  async mounted() {
    await this.fetchLocations(this.tab, this.date[0], this.date[1])
  },
  methods: {
    ...mapActions({
      fetchLocations: 'masters/fetchLocations',
    }),
    changeDatePicker() {
      this.tab = 'custom'

      this.fetchLocations(this.tab, this.date[0], this.date[1])
    },
    changeDateTabs() {
      if (this.tab === 'today') {
        this.date = [new Date(), new Date()]
      } else if (this.tab === 'yesterday') {
        this.date = [new Date().removeDays(1), new Date().removeDays(1)]
      } else if (this.tab === 'week') {
        this.date = [new Date().removeDays(6), new Date()]
      } else if (this.tab === 'month') {
        this.date = [new Date().removeMonths(1), new Date()]
      } else if (this.tab === 'quarter') {
        this.date = [new Date().removeMonths(3), new Date()]
      } else if (this.tab === 'year') {
        this.date = [new Date().removeMonths(12), new Date()]
      }

      this.fetchLocations(this.tab, this.date[0], this.date[1])

      const labels = document.querySelectorAll('.accordion-label')
      labels.forEach(label => {
        label.classList.remove('not-collapsed')
      })
    },
  },
}
</script>
